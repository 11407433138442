

//splash


let currentPixel = window.pageYOffset;
let toSkew = document.querySelectorAll('.skew');


function skew(){
    let newPixel = window.pageYOffset;
    let increment = newPixel - currentPixel;
    let speed = increment * 0.09;
    toSkew.forEach((el) => {
        el.style.transform = "skewY(" + speed + "deg)";
    })

    currentPixel = newPixel;
    requestAnimationFrame(skew)
}

skew();

const grid = document.getElementById('grid');

if(grid){
    var pckry = new Packery( '.grid', {
        itemSelector: '.grid-item',
        gutter: 20
    });
}

const gridbis = document.getElementById('gridshow');

if(gridbis){
    console.log('gridbis');
    var pckry = new Packery( '.gridshow', {
        itemSelector: '.grid-item',
        gutter: 20
    });
}

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});


const burger = document.getElementById('burger');
const header = document.getElementById('top');

burger.addEventListener('click', () =>{
    header.classList.toggle('active')
})

// $('.moreless-button').click(function () {
//     $(this).prev().slideToggle();

//     if ($(this).text() == "Lire la suite") {
//         $(this).text("Lire moins");
//     } else {
//         $(this).text("Lire la suite");
//     }
// });
jQuery(function (){
    // jQuery('article.readmore').readmore({
    //     lessLink: '<a class="morebtn" href="javascript:;">Lire moins</a>',
    //     moreLink: '<a class="morebtn" href="javascript:;">Lire la suite</a>',
    //     collapsedHeight : 105
    // });

    $('.marques-carousel').slick({
        arrows: false,
        dots: true,
        infinite: true,
        centerMode: false,
        speed: 300,
        slidesToShow: 1,
        mobileFirst: true,

        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            }
        ]
    });
    
});